import React from 'react';
import emails from "../constant/emails";
import Icon1 from "../../assets/website/images/Icon-1.png";
import Icon2 from "../../assets/website/images/Icon-2.png";
import UsImg from "../../assets/website/images/US Flag.png";
import CanadaImg from "../../assets/website/images/Canada.webp";
import "../../assets/website/css/footerContactInfo.css";

const footerContactInfo = () => {

    const footerContantInfoData = [
        {
            id:1,
            name: "United States",
            phn_number: "1-754-205-9581",
            img : UsImg,
        },
        {
            id:2,
            name: "Canada",
            phn_number: "613-282-5558",
            img : CanadaImg,
        }
    ]
  return (
    footerContantInfoData.map((item, index)=>{
   
   
    return <div className='footer-contact-info-parent-container'>
        <div className='footer-contact-info-parent-left-content'>
            <img src={item.img}/>
        </div>
         <div className="footer-contact-info-parent-right-content">
         <p>{item.name} </p>
                    <div className="InfoBox">
                    <span className="Icon">
                          <img src={Icon1} />
                        </span>
                        
                        <h5>{item.phn_number}</h5>
                      </div>
                      <div className="">
                      <div className="InfoBox">
                        <span className="Icon">
                          <img src={Icon2} />
                        </span>
                        {/* <p>{t("website.footer.Email")} : </p> */}
                        <h5>
                          {" "}
                          <a
                            href={"mailto:"+emails.sales}
                            style={{ color: "#f2cf00" }}
                          >
                            {emails.sales}
                          </a>{" "}
                          <br />{" "}
                          <a
                            href={"mailto:"+emails.info}
                            style={{ color: "#f2cf00" }}
                          >
                            {emails.info}
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                    </div>
    </div>
     })
  )
}

export default footerContactInfo