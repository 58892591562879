import React, { useState, useEffect, Suspense, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Header from "../website/header";
import { isCommonLoggedIn } from "../../utils";
import "../../assets/common-css.css";
import TestimonialSlider from "../website/testimonialSlide";

// import Slider1 from "../../assets/website/images/slider1.webp";
import Slider1 from "../../assets/website/images/Slider_1.png";
import Slider2 from "../../assets/website/images/Slider_2.png";
import Slider3 from "../../assets/website/images/Slider_3.png";
import Slider4 from "../../assets/website/images/slider4.webp";

import Service1 from "../../assets/website/images/Service-1.webp";
import Service2 from "../../assets/website/images/Service-2.webp";
import Service3 from "../../assets/website/images/new_car_showroom.webp";
import WebsiteAd from "./websiteAd";

const Home = () => {
  const { t } = useTranslation();
  const [loginShow, setLoginShow] = useState(false);
  const handleClose = () => setLoginShow(false);
  const handleShow = () => setLoginShow(true);
  const [calculatedHeightForCarousel, setCalculatedHeightForCarousel] = useState(null);

  const [headerLogin, setHeaderLogin] = useState({});
  useEffect(() => {
    let getHeaderLogin = isCommonLoggedIn();
    setHeaderLogin(getHeaderLogin);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: "auto",
    });
  }, []);

  const LazyModalBox = React.lazy(() => import('./modal'));
  const LazyToastContainer = React.lazy(() => import('react-toastify').then(module => ({ default: module.ToastContainer })));
  const LazyFooter = React.lazy(() => import('../website/footer'));

  const sliderImages = [Slider1, Slider2, Slider3];

  useEffect(() => {
    updateCarouselHeight();
  }, []);

  const updateCarouselHeight = () => {
    const slideContentEle = document.querySelector(".SlideContent");
    if(slideContentEle){
      console.log(slideContentEle, slideContentEle.clientHeight)
      const height = slideContentEle.clientHeight;
      setCalculatedHeightForCarousel(height);
    }else{
      console.log("slideContentEle ot found use ref")
    }
  }
  

  return (
    <>
    <Suspense fallback={<div></div>}>
      <LazyToastContainer />
    </Suspense>
      <Header />

      { loginShow && 
      <Suspense fallback={<div></div>}>
        <LazyModalBox
          loginShow={loginShow}
          handleClose={handleClose}
          handleShow={handleShow}
          aciveShow={false}
        />  
      </Suspense>
      }

<div className="SliderAreaWrapper">
    {/* Left side - Slider */}
    <div className="SliderArea" style={calculatedHeightForCarousel && {height: calculatedHeightForCarousel}} >
    <div id="Slide" className="carousel slide" data-ride="carousel">
          {/* <ul className="carousel-indicators carousel_indicator_mvp">
            <li data-target="#Slide" data-slide-to="0" className="active"></li>
            <li data-target="#Slide" data-slide-to="1"></li>
            <li data-target="#Slide" data-slide-to="2"></li>
            <li data-target="#Slide" data-slide-to="3"></li>
          </ul> */}
          <div className="carousel-inner">
            {sliderImages.map((i, idx)=>{
              return <div className={`carousel-item ${idx === 0 && 'active'}`}>
              <img alt="" src={i} height={calculatedHeightForCarousel} />
            </div>
            })}
          </div>
          <a className="carousel-control-prev" href="#Slide" data-slide="prev">
            <i className="fa fa-angle-left"></i>
          </a>
          <a className="carousel-control-next" href="#Slide" data-slide="next">
            <i className="fa fa-angle-right"></i>
          </a>
        </div>
    </div>
     {/* Right side - Slide Content */}
    <div className="SlideContent slider_text_hide_mvp">
      <div className="public-portal">
      <h3> {t("website.home.Allyourautomotiveneedsinoneplace")} </h3>
          <p>{t("website.home.Ourvehiclesareoneofthemost")}</p>
          <ul>
            <li>
              <Link to="/auto-repair">{t("website.home.AutoRepair")}</Link>
            </li>
            <li>
              <Link to="/auto-parts">{t("website.home.AutoParts")}</Link>
            </li>
            <li>
              <Link to="/auto-sales">{t("website.home.AutoSales")}</Link>
            </li>
            <li>
              <Link to="/auto-sales">{t("website.home.AutoServices")}</Link>
            </li>
          </ul>
      </div>
      <div className="corporatePortal">
      <img src={Service1} alt="Corporate Portal" onLoad={updateCarouselHeight} />
      <h3>CORPORATE PORTAL</h3>
      <div className="buttons">
        <button className="btn-gray">Register</button>
        <button className="btn-red">Login</button>
      </div>
    </div>
        
        </div>
   
</div>

    {/*      
      <div className="need_help">
        <h3> {t("website.home.NeedHelpWithYourVehicle")} </h3>
        <h6> {t("website.home.SelectButtonService")} </h6>
        <div className="need_help_btn_group">
          <span className="need_help_btn">
            <Link to="/auto-repair">{t("website.home.AutoRepair")}</Link>
          </span>
          <span className="need_help_btn">
            {" "}
            <Link to="/auto-parts">{t("website.home.AutoParts")}</Link>
          </span>
          <span className="need_help_btn">
            <Link to="/auto-sales">{t("website.home.AutoSales")}</Link>
          </span>
        </div>
      </div> */}
      <section>
        <div className="ServiceArea">
          <div className="container">
            <h3> {t("website.home.Areyouanautoserviceprovider?")} </h3>
            <p className="ServiceArea-container-heading-para">Accelerate your business with autowiz and make it more interesting.</p>
            <div className="row">
              <div className="col-sm-4">
                <div className="ServiceBox">
                  <figure>
                    <img alt="" src={Service1} />
                  </figure>
                  <figcaption>
                    <h4> {t("website.home.GarageOwners&TowTrucks")} </h4>
                    <aside>
                      <p> {t("website.home.Foradditionalinformation")} </p>
                    </aside>
                    <article>
                      {headerLogin &&
                      Object.getOwnPropertyNames(headerLogin).length != 0 ? (
                        <Link to="/service-auto-repair">
                          {" "}
                          {t("website.home.Moreinfo")}{" "}
                        </Link>
                      ) : (
                        <>
                          <Link to="/service-auto-repair">
                            {t("website.home.Moreinfo")}
                          </Link>
                          <Link to="" onClick={handleShow}>
                            {t("website.home.Login")}
                          </Link>
                        </>
                      )}
                    </article>
                  </figcaption>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ServiceBox">
                  <figure>
                    <img alt="" src={Service2} />
                  </figure>
                  <figcaption>
                    <h4> {t("website.home.AutoPartsVendors")} </h4>
                    <aside>
                      <p> {t("website.home.Autowizisincontact")} </p>
                    </aside>
                    <article>
                      {headerLogin &&
                      Object.getOwnPropertyNames(headerLogin).length != 0 ? (
                        <Link to="/service-auto-parts">
                          {t("website.home.Moreinfo")}
                        </Link>
                      ) : (
                        <>
                          <Link to="/service-auto-parts">
                            {t("website.home.Moreinfo")}
                          </Link>
                          <Link to="" onClick={handleShow}>
                            {t("website.home.Login")}
                          </Link>
                        </>
                      )}
                    </article>
                  </figcaption>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ServiceBox">
                  <figure>
                    <img
                      alt=""
                      src={Service3}
                      className="img_new_car_showroom"
                    />
                  </figure>
                  <figcaption>
                    <h4> {t("website.home.AutoSalesdealers")} </h4>
                    <aside>
                      <p>{t("website.home.Ifyouareinthemarket")}</p>
                    </aside>
                    <article>
                      {headerLogin &&
                      Object.getOwnPropertyNames(headerLogin).length != 0 ? (
                        <Link to="/service-auto-sales">
                          {t("website.home.Moreinfo")}
                        </Link>
                      ) : (
                        <>
                          <Link to="/service-auto-sales">
                            {t("website.home.Moreinfo")}
                          </Link>
                          <Link to="" onClick={handleShow}>
                            {t("website.home.Login")}
                          </Link>
                        </>
                      )}
                    </article>
                  </figcaption>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TestimonialSlider />
      <WebsiteAd/>
      <Suspense fallback={<div></div>}>
        <LazyFooter />
      </Suspense>
    </>
  );
};

export default Home;
