import React from "react";
import "../../assets/website/css/websiteAd.css";
import webAdImg from "../../assets/website/images/webAd.jpg";

const WebsiteAd = () => {
    return (
      <div className="website-ad-parent-container">
     <h2>Looking to build a website?</h2>
     <p className="">You can get more visibility among your customers & accelerate your business.</p>
     <div className="website-ad-content-wrapper">
     <h4 className="">
            Drive More Customers to your business with a High-Performance Website
          </h4>
          <div className="website-add-left-right-parent-container">
          <div className="website-ad-content-left">
          <p className="">
            Your website isn’t just a digital business card — it’s a sales engine. Let’s build you a sleek, powerful, 
            and customer-focused online presence.
          </p>
          <div className="website-ad-button-gray-yellow">
  <button className="button-gray">Know More</button>
  <button className="button-yellow">Build your site Today</button>
</div>
          </div>
          <div className="website-ad-content-right">
            <img src={webAdImg}></img>
          </div>
          </div>
        
     </div>
      </div>
    );
  };
  
  export default WebsiteAd;