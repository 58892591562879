import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceImg from "../../assets/website/images/Service-1.webp";
import "../../assets/website/css/testimonialSlide.css";

const testimonials = [
 

  {
    id: 1,
    name: "James R.",
    role: " A Game-Changer for Car Maintenance!",
    feedback:
"I used to struggle to find a reliable garage for my car servicing, but Autowiz changed everything! I can compare prices, book appointments easily, and even chat with mechanics before taking my car in. Highly recommend!",
    image: ServiceImg, 
    author : "— James R., Montreal, QC"
  },
  {
    id: 2,
    name: "Linda M.",
    role: "Hassle-Free Car Repairs!",
    feedback:
"Finding a trustworthy mechanic has always been a challenge, but Autowiz made it so simple! I got multiple quotes, scheduled a service, and my car was fixed on time. No hidden charges, no stress!",
    image: ServiceImg,
    author: "— Linda M., Markham, ON"
  },
  {
    id: 3,
    name: "Mark T.",
    role: "Great for Busy People!",
    feedback:
"As someone with a packed schedule, I love how I can book a service through Autowiz without wasting time calling different garages. The real-time updates and easy payment options are a huge plus!",
    image: ServiceImg,
    author: "— Mark T., Tampa, FL"
  },
  {
    id: 4,
    name: "Sophia K.",
    role: "Transparent Pricing & Honest Reviews",
    feedback:
"I was tired of overpaying for car repairs, but Autowiz helped me find a garage with fair pricing and great reviews. Now, I always know what to expect before I book!",
    image: ServiceImg,
    author:"— Sophia K., Ottawa, ON"
  },
  {
    id: 5,
    name: "David P.",
    role: "Perfect for Emergency Repairs!",
    feedback:
"My car broke down unexpectedly, and I had no idea where to go. Within minutes, I found a nearby garage on Autowiz, checked reviews, and got my car towed and repaired quickly. Lifesaver!",
    image: ServiceImg,
    author:"— David P., Miami, FL"
  }
];

const TestimonialSlider = () => {

  const [biggestTestimonialCardHeight, setBiggestTestimonialCardHeight] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // centerMode: true,
    // centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(()=>{

    let largeresHeightCard = 0;
    const cardsEle = document.querySelectorAll(".testimonial-card");
    cardsEle.forEach(ele=>{
      if(ele.clientHeight > largeresHeightCard){
        largeresHeightCard = ele.clientHeight;
      }
    });
    console.log("largeresHeightCard", largeresHeightCard)
    setBiggestTestimonialCardHeight(largeresHeightCard);

  }, []);

  return (
    <div className="testimonial-section">
      <h2>What Our Clients Say, Testimonials</h2>
      <p>
        Don't just take our word for it—see what our happy customers have to say
        about their experience with us!
      </p>
      <div className="slider-container">

      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div>
            <div key={testimonial.id} className="testimonial-card" style={biggestTestimonialCardHeight && {height: biggestTestimonialCardHeight}} >
              {/* <img
                src={testimonial.image}
                alt={testimonial.name}
                className="testimonial-image"
                /> */}
              <h3>{testimonial.name}</h3>
              <h4>{testimonial.role}</h4>
              <p>"{testimonial.feedback}"</p>
              <p> {testimonial.author} </p>
            </div>
          </div>
        ))}
      </Slider>
      </div>
    </div>
  );
};

export default TestimonialSlider;
