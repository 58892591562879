import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import _ from 'lodash'
import FORM from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Moment from 'moment';

import Header from "./header";
import Footer from "./footer";

import { manufacturerList, modelList, vehicleList, vehicleCreate, vehicleView, vehicleUpdate, vehicleDelete } from "../../redux/actions/provider/vehicleAction";

const searchInitialState = {
    search:''
}

const initialState = {
    model:'',
    make:'',
    mileage:'',
    type:'',
    imageGallery:'',
    modelEmpty:'',
    makeEmpty:'',
    mileageEmpty:'',
    typeEmpty:'',
    imageGalleryEmpty:'',
    year: '',
    color: '',
    condition: '',
    description: '',
}

const editState = {
    idEdit:'',
    modelEdit:'',
    makeEdit:'',
    mileageEdit:'',
    typeEdit:'',
    imageGalleryEdit:'',
    modelEditEmpty:'',
    makeEditEmpty:'',
    mileageEditEmpty:'',
    typeEditEmpty:'',
    imageGalleryEditEmpty:'',
    yearEdit: '',
    colorEdit: '',
    conditionEdit: '',
    descriptionEdit: '',
}

const MAX_NUMBER_OF_IMAGE_UPLOAD = 5;

var imagePreview = [];
var imageFile = [];

var imageEditPreview = [];
var imageEditFile = [];



const VehicleCarousel = (props)=>{
    const {item} = props;
    const contianerRef = useRef();

    const handleArrowClick = (action)=>(e)=>{
        if(contianerRef.current){
            const scrollValue = action === "right" ? 150 : -150;
            contianerRef.current.scrollBy(scrollValue,0);

        }
    }

    return (<>
            <div className='scroller my-vehicle' ref={contianerRef}>
                {                                      
                    item.imageGallery.map(img=>{
                        return (
                            <div key={img}>
                                <img src={img} />
                            </div>
                        )
                    })
                }
            </div>
            <div className='scroller-arrows user-select-none'>
                <span onClick={handleArrowClick("left")} >&larr;</span>
                <span onClick={handleArrowClick("right")}>&rarr;</span>
            </div>
        </>
    )
}

const SalesMyVehicle = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const toastId = React.useRef(null);
    const [vehicleEditData, setVehicleEditData] = useState({});

    const getListData = useSelector(state => state.salesVehicle)
    const { manufacturerSalesData, modelSalesData, salesVehicleData, salesEditVehicleData, loader } = getListData
    useEffect(() => {
        const data = {keyword:''}
        dispatch(vehicleList(data))
        dispatch(manufacturerList())
    },[])
    const [listState, setListState] = useState([]);
    const [listFilterState, setListFilterState] = useState([]);

    useEffect(() => {
        setListState(salesVehicleData)
        setListFilterState(salesVehicleData)
    },[salesVehicleData])

    useEffect(() => {
        if (!_.isEmpty(salesEditVehicleData)) {

            const updateData = _.cloneDeep(veState)
            updateData.idEdit = salesEditVehicleData._id
            updateData.modelEdit = salesEditVehicleData.model
            updateData.makeEdit = salesEditVehicleData.make
            updateData.mileageEdit = salesEditVehicleData.mileage
            updateData.typeEdit = salesEditVehicleData.type?salesEditVehicleData.type:''
            updateData.colorEdit = salesEditVehicleData.color;
            updateData.conditionEdit = salesEditVehicleData.condition;
            updateData.descriptionEdit = salesEditVehicleData.description;
            updateData.yearEdit = salesEditVehicleData.year;

            setSelectedImagePreview({ file: salesEditVehicleData.imageGallery })

            updateVEState(
                updateData
            )
        }
    },[salesEditVehicleData])

    useEffect(() => {
        if(!loader && toastId.current) {
            toast.dismiss(toastId.current);
        }
    }, [loader]);
    

    const [searchState, updateSearchState] = useState(searchInitialState);
    const {
        search,
        searchEmpty
    } = searchState
    const handleSearchInputChange = (e) => {
        const { name, value } = e.target
        updateSearchState({
            ...searchState, [name]: value
        })
    }
    const handleSearchValidation = () => {

        let searchEmpty = ''
        let formIsValid = true;
        if (!search.trim()) {
            searchEmpty = "Please enter search";
            formIsValid = false;
        }
        updateSearchState({
            ...searchState, 
            searchEmpty,
        })
        return formIsValid;
    }
    let handleSearchSubmit = (event) => {
        event.preventDefault();

        let formIsValid = handleSearchValidation();
        if (formIsValid) {
            var filterData = listState.filter((item) => {
                if(item.model == search || item.make == search || item.mileage == search) {
                    return item;
                }
            })
            setListFilterState(filterData)
        }
    }

    const [addVehicleShow, setAddVehicleShow] = useState(false);
    const handleAddVehicleClose = () => setAddVehicleShow(false);
    const handleAddVehicleShow = () => { setAddVehicleShow(true); }

    const [editVehicleShow, setEditVehicleShow] = useState(false);

    const handleEditVehicleClose = () =>{ 
        setEditVehicleShow(false);
        setVehicleEditData({});
    };

    const [vState, updateVState] = useState(initialState);
    const {
        model,
        make,
        mileage,
        type,
        imageGallery,
        modelEmpty,
        makeEmpty,
        mileageEmpty,
        typeEmpty,
        imageGalleryEmpty,
        color,
        condition,
        description,
        year,
    } = vState

    const handleInputChange = (e) => {
        const { name, value } = e.target
        updateVState({
            ...vState, [name]: value
        })
        if(name == "make") {
            let dependentData = { name:value };
            dispatch(modelList(dependentData))
        }
    }

    const handleValidation = () => {

        let modelEmpty = ''
        let makeEmpty = ''
        let mileageEmpty = ''
        let typeEmpty = ''

        let formIsValid = true;
        
        if (!model.trim()) {
            modelEmpty = "Please enter model";
            formIsValid = false;
        }
        if (!make.trim()) {
            makeEmpty = "Please enter make";
            formIsValid = false;
        }
        if (!mileage.trim()) {
            mileageEmpty = "Please enter mileage";
            formIsValid = false;
        }
        // if (!type.trim()) {
        //     typeEmpty = "Please enter type";
        //     formIsValid = false;
        // }
        updateVState({
            ...vState,
            modelEmpty,
            makeEmpty,
            mileageEmpty,
            typeEmpty
        })
        return formIsValid;
    }

    const [selectedImage, setSelectedImage] = useState([]);
    const [selectedImagePreview, setSelectedImagePreview] = useState([]);

    const imageChange = (e) => { // vehicle image change on add flow
        
        if(imagePreview.length < MAX_NUMBER_OF_IMAGE_UPLOAD) {
            if (e.target.files && e.target.files.length > 0) {
                
                imageFile.push(e.target.files[0])
                imagePreview.push(URL.createObjectURL(e.target.files[0]))
                setSelectedImage({ file: imageFile });
                setSelectedImagePreview([...imagePreview]);
                e.target.value = null;
            }
        } else {
            toast.error(`Image can not be upload more than ${MAX_NUMBER_OF_IMAGE_UPLOAD}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const removeSelectedImage = (i) => {
        imagePreview.splice(i, 1)
        imageFile.splice(i, 1)
        setSelectedImage({ file: imageFile });
        setSelectedImagePreview([...imagePreview]);
    };

    const [selectedEditImage, setSelectedEditImage] = useState([]);
    const [selectedEditImagePreview, setSelectedEditImagePreview] = useState([]);

    const uploadMultipleFiles = (e) => {
        /**
         * If imageGalleryFile exist then we need to call upload API (which is not implemented yet)
         * imageGallery will be used for exisitng images in database and update this array based on imageGalleryFile    
        */
        if(vehicleEditData.imageGallery.length < MAX_NUMBER_OF_IMAGE_UPLOAD) {
            if (e.target.files && e.target.files.length > 0) {
                const imageFile = URL.createObjectURL(e.target.files[0]);

                setVehicleEditData(prevData=>{
                    !prevData.imageGalleryFile && (prevData.imageGalleryFile = []);
                    return {
                        ...prevData,
                        imageGallery: [...prevData.imageGallery, imageFile],
                        imageGalleryFile: [...prevData.imageGalleryFile, e.target.files[0]]
                    }
                });
            }
        } else {
            toast.error("Image can not be upload more than "+MAX_NUMBER_OF_IMAGE_UPLOAD, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const removeSelectedEditImage = (i) => { // remove image from edit vehicle

        imageEditPreview.splice(i, 1)
        imageEditFile.splice(i, 1)
        setSelectedEditImage({ file: imageFile });
        setSelectedImagePreview(imageEditPreview);
        setVehicleEditData(prevData=>{
            prevData.imageGallery.splice(i, 1);
            return {
                ...prevData,
            }
        })

    };

    const [buttonDisable, setButtonDisable] = useState(false);
    let handleVehicleSubmit = (event) => {
        event.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {

            setButtonDisable(true)

            const data = { 
                model,
                make,
                mileage,
                type,
                imageGallery: selectedImage?selectedImage.file:[],
                color,
                condition,
                description,
                year,
            };
            toastId.current = toast("Processing...Please wait for a moment");
            dispatch(vehicleCreate(data)).then(res => {
                if (res.code == 201) {
                    handleAddVehicleClose();
                    toast.success(res.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setTimeout(
                        function() {
                            window.location.reload()
                        }
                        .bind(this),
                        2000
                    );
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    updateVState({
                        ...vState, 
                        errorMsg: res.message,
                        modelEmpty: '',
                        makeEmpty: '',
                        mileageEmpty: '',
                        typeEmpty: ''
                    })
                }
            }).catch(err => {

                setButtonDisable(false)
                const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    }

    // Edit vehicle state
    const [veState, updateVEState] = useState(editState);
    const {
        idEdit,
        modelEdit,
        makeEdit,
        mileageEdit,
        typeEdit,
        imageGalleryEdit,
        modelEditEmpty,
        makeEditEmpty,
        mileageEditEmpty,
        typeEditEmpty,
        imageGalleryEditEmpty,
        colorEdit,
        conditionEdit,
        descriptionEdit,
        yearEdit
    } = veState

    const handleEditVehicleShow = (id) => { // vehicle edit button click (edit flow)
        const data = { 
            id: id
        }
        dispatch(vehicleView(data)).then(res => {
            
            if (res.code == 200) {
                setVehicleEditData(res.data);
                setEditVehicleShow(true);
            } else {
                let errors = res.errors.errors
                errors.map((item) => {
                    var newParam = item.param
                    let messageErr = item.msg +' of '+ newParam
                    toast.error(messageErr, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            }
        }).catch(err => {
            console.log(err, 'err')
            const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    }

    const handleEditInputChange = (e) => {
        const { name, value } = e.target
        updateVEState({
            ...veState, [name]: value
        })

        if(name == "make") {
            let dependentData = { name:value };
            dispatch(modelList(dependentData))
        }
    }
    const handleVehicleEditChange = (e) => {
        const { name, value } = e.target
        setVehicleEditData({ ...vehicleEditData, [name]: value });
    }
    const handleEditValidation = () => {

        let modelEditEmpty = ''
        let makeEditEmpty = ''
        let mileageEditEmpty = ''
        let typeEditEmpty = ''

        let formIsValid = true;
        if (!modelEdit.trim()) {
            modelEditEmpty = "Please enter model";
            formIsValid = false;
        }
        if (!makeEdit.trim()) {
            makeEditEmpty = "Please enter make";
            formIsValid = false;
        }
        if (!mileageEdit.trim()) {
            mileageEditEmpty = "Please enter mileage";
            formIsValid = false;
        }
        if (!typeEdit.trim()) {
            typeEditEmpty = "Please enter type";
            formIsValid = false;
        }

        updateVEState({
            ...veState, 
            modelEditEmpty,
            makeEditEmpty,
            mileageEditEmpty,
            typeEditEmpty
        })
        return formIsValid;
    }

    let handleVehicleEditSubmit = (event) => {
        event.preventDefault();
        let formIsValid = handleEditValidation();
        if (formIsValid) {
            const data = { 
                id: idEdit, 
                model: modelEdit, 
                make: makeEdit, 
                mileage: mileageEdit, 
                type: typeEdit, 
                imageGalleryEdit: selectedImage?selectedImage.file:[],
                color: colorEdit,
                condition: conditionEdit,
                description: descriptionEdit,
                year: yearEdit,
            }
            dispatch(vehicleUpdate(data)).then(res => {
                
                if (res.code == 201) {

                    toast.success(res.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    
                    handleEditVehicleClose();
                    const data = {keyword:''}
                    dispatch(vehicleList(data))

                } else {
                    // console.log(res);
                    let errors = res.errors.errors
                    errors.map((item) => {
                        var newParam = item.param
                        let messageErr = item.msg +' of '+ newParam
                        toast.error(messageErr, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                    updateVEState({
                        ...veState, 
                        errorMsg: res.message,
                        modelEditEmpty: '',
                        makeEditEmpty: '',
                        mileageEditEmpty: '',
                        typeEditEmpty: ''
                    })
                }
            }).catch(err => {
                console.log(err, 'err')
                const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    }

    const handleVehicleEditSubmitV2 = (event) => {
        event.preventDefault();
        let formIsValid = true;
        if (formIsValid) {
            dispatch(vehicleUpdate({...vehicleEditData, id: vehicleEditData._id})).then(res => {
                
                if (res.code == 201) {

                    toast.success(res.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    
                    handleEditVehicleClose();
                    const data = {keyword:''}
                    dispatch(vehicleList(data))

                } else {
                    // console.log(res);
                    let errors = res.errors.errors
                    errors.map((item) => {
                        var newParam = item.param
                        let messageErr = item.msg +' of '+ newParam
                        toast.error(messageErr, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                    updateVEState({
                        ...veState, 
                        errorMsg: res.message,
                        modelEditEmpty: '',
                        makeEditEmpty: '',
                        mileageEditEmpty: '',
                        typeEditEmpty: ''
                    })
                }
            }).catch(err => {
                console.log(err, 'err')
                const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    }

    const [deleteId, setDeleteIdShow] = useState();
    const [deleteShow, setDeleteShow] = useState(false);
    const handleDeleteClose = () => {
        setDeleteShow(false)
    }
    const handleDeleteShow = (id) => { 
        setDeleteShow(true)
        setDeleteIdShow(id)
    };

    const handleDeleteVehicle = () => {
        if(deleteId) {
            var data = {id: deleteId}
            dispatch(vehicleDelete(data)).then(res => {
                if (res.code == 200) {
                    toast.success(res.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    handleDeleteClose()
                    const data = {keyword:''}
                    dispatch(vehicleList(data))
                } else {
                    let errors = res.errors.errors
                    errors.map((item) => {
                        var newParam = item.param
                        let messageErr = item.msg +' of '+ newParam
                        toast.error(messageErr, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                }
            }).catch(err => {
                console.log(err, 'err')
                const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    }

    const handleMarkSoldClick = (item)=>()=>{

        const data = { 
            ...item,
            id: item._id,
            isSold: true
        }
        dispatch(vehicleUpdate(data)).then(res => {
            if (res.code == 201) {

                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                
                handleEditVehicleClose();
                const data = {keyword:''}
                dispatch(vehicleList(data))

            } else {
                // console.log(res);
                let errors = res.errors.errors
                errors.map((item) => {
                    var newParam = item.param
                    let messageErr = item.msg +' of '+ newParam
                    toast.error(messageErr, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            }
        }).catch(err => {
            console.log(err, 'err')
            const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

    }

    return (
        <>
            <ToastContainer/>
            <Header />

            <div className="WrapperArea">
                <div className="WrapperBox"> 
                    <div className="TitleBox">
                        <h4>My Vehicles</h4>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="SearchBox">
                            <FORM onSubmit={handleSearchSubmit}>
                                <input type="text" name="search" placeholder="Search Vehicle" value={search} onChange={handleSearchInputChange} />
                                <button type="submit">Search</button>
                            </FORM>
                            {
                                salesVehicleData && salesVehicleData.length>20?
                                    "You can not add more than 20 vehicles"
                                : <a href="JavaScript:void(0);" onClick={handleAddVehicleShow}>Add Vehicle</a>
                            }
                        </div>
                        <div>
                            {
                                listFilterState && listFilterState.length>0 && listFilterState.map((item, i)=>{
                                    return (
                                        <>
                                            <div className='bg-white border border-dark rounded d-flex gap-2 mb-3 p-2'>
                                                <div>
                                                    <VehicleCarousel item={item} />
                                                </div>
                                                <div className='flex-grow-1 px-2' >
                                                    <div className='d-flex flex-wrap justify-content-evenly my-vehicle-info-container mb-2' >
                                                        {item.make && 
                                                            <div>
                                                                Manufacturer: <strong>{item.make}</strong>
                                                            </div>
                                                        }
                                                        {item.model && 
                                                            <div>
                                                                Model: <strong>{item.model}</strong>
                                                            </div>
                                                        }
                                                        {item.type && 
                                                            <div>
                                                                Type: <strong>{item.type}</strong>
                                                            </div>
                                                        }
                                                        {item.year && 
                                                            <div>
                                                                Year: <strong>{item.year}</strong>
                                                            </div>
                                                        }
                                                        {item.color && 
                                                            <div>
                                                                Color: <strong>{item.color}</strong>
                                                            </div>
                                                        }
                                                        {item.mileage && 
                                                            <div>
                                                                Mileage: <strong>{item.mileage}</strong>
                                                            </div>
                                                        }
                                                        {item.condition && 
                                                            <div>
                                                                Condition: <strong>{item.condition}</strong>
                                                            </div>
                                                        }

                                                    </div>

                                                    {item.description && <div>
                                                        Description: 
                                                        <br />
                                                        
                                                        <strong>{item.description}</strong>
                                                    </div>}


                                                </div>
                                                <div className="d-flex flex-column justify-content-between ">
                                                    <div className='d-flex justify-content-justify-content-evenly g-5'>
                                                        <a className="Green text-success mr-5" title="Edit" href="JavaScript:void(0);" onClick={()=>handleEditVehicleShow(item._id)}>
                                                            <i className="fa fa-pencil-square-o"></i>
                                                        </a>
                                                        <a className="Red text-danger" title="Delete" href="JavaScript:void(0);" onClick={()=>handleDeleteShow(item._id)}>
                                                            <i className="fa fa-trash"></i>
                                                        </a>  
                                                    </div> 
                                                    <div>
                                                        {
                                                            !item.isSold ? 
                                                            <button className="bg-success text-white p-2 rounded font-weight-bold text-nowrap" onClick={handleMarkSoldClick(item)}>Mark as Sold</button>
                                                            :
                                                            <div className="text-danger font-weight-bold">Marked as Sold</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div> 

            <Modal show={addVehicleShow} className="PanelModal PanelGallery">
                <div className="modal-body">
                    <div className="Category">
                        <a href="javascript:void(0);" className="Close" onClick={handleAddVehicleClose}>×</a>
                        <h3> { t('sales.vehicle.Addvehicle') } </h3>
                        <FORM onSubmit={handleVehicleSubmit}>
                            
                            <div className="form-group">  
                                <label> { t('sales.vehicle.Make') } </label>
                                <select className="form-control" name="make" autoComplete="off" value={make} onChange={handleInputChange}>
                                    <option value=""> Select </option>
                                    {
                                        manufacturerSalesData && manufacturerSalesData.length>0 && manufacturerSalesData.map(item=>
                                            <option value={item._id}> {item._id} </option>
                                        )
                                    }
                                </select>
                                <span style={{ color: "red" }}>{makeEmpty}</span>
                            </div> 
                            <div className="form-group">  
                                <label> { t('sales.vehicle.Model') } </label>
                                <select className="form-control" name="model" value={model} onChange={handleInputChange}>
                                    <option value=""> Select Model </option>
                                    {
                                        modelSalesData && modelSalesData.length>0?
                                            modelSalesData.map((item, i) =>
                                                <option value={ item.model }> { item.model } </option>
                                            )
                                        :
                                        ''
                                    }
                                </select>
                                <span style={{ color: "red" }}>{modelEmpty}</span>
                            </div> 

                            <div className="form-group">  
                                <label>Year</label>
                                    <input type="text" className="form-control" placeholder="Enter Year" name="year" value={year} onChange={handleInputChange} />
                                {/* <span style={{ color: "red" }}>{mileageEmpty}</span> */}
                            </div>
                            <div className="form-group">  
                                <label>Color</label>
                                    <input type="text" className="form-control" placeholder="Enter color" name="color" value={color} onChange={handleInputChange} />
                                {/* <span style={{ color: "red" }}>{mileageEmpty}</span> */}
                            </div>
                            <div className="form-group">  
                                <label>Condition</label>
                                    {/* <input type="text" className="form-control" placeholder="Condition" name="condition" value={condition} onChange={handleInputChange} /> */}
                                    <select className="form-control" name="condition" value={condition} onChange={handleInputChange}>
                                        <option value="" disabled> Select Vehicle condtion </option>
                                        <option value="New"> New </option>
                                        <option value="Used"> Used </option>
                                    </select>
                                {/* <span style={{ color: "red" }}>{mileageEmpty}</span> */}
                            </div>
                            <div className="form-group">  
                                <label>Description</label>
                                    <input type="text" className="form-control" placeholder="Enter description" name="description" value={description} onChange={handleInputChange} />
                                {/* <span style={{ color: "red" }}>{mileageEmpty}</span> */}
                            </div> 

                            <div className="form-group">  
                                <label> { t('sales.vehicle.Mileage') } </label>
                                <input type="text" className="form-control" placeholder="Enter Mileage" name="mileage" value={mileage} onChange={handleInputChange} />
                                <span style={{ color: "red" }}>{mileageEmpty}</span>
                            </div> 
                            <div className="form-group">  
                                <label> { t('sales.vehicle.Type') } </label>
                                <input className="form-control" placeholder="Sports, etc" name="type" value={type} onChange={handleInputChange} />
                                <span style={{ color: "red" }}>{typeEmpty}</span>
                            </div> 

                            <div className="form-group">
                                <ul className="ImaesGroup">
                                    <li>
                                        <div className="AddImages">
                                            <span><i className="fa fa-plus" aria-hidden="true"></i></span>
                                            <p>Add Images</p>
                                            <input type="file" accept="image/*" name="images" onChange={imageChange}/>
                                        </div>
                                    </li>
                                    {
                                        selectedImagePreview && (selectedImagePreview.length > 0) ? 
                                        selectedImagePreview.map((img, i) =>
                                                <li key={i}>
                                                    <button type="button" className="Close" onClick={()=>removeSelectedImage(i)}>×</button>
                                                    <img src={img} />
                                                </li>
                                            )
                                        : ""
                                    }
                                </ul>
                            </div>

                            <button type="submit" className="Accept" disabled={buttonDisable}> { t('sales.vehicle.Addvehicle') } </button>
                        </FORM>
                    </div>
                </div>
            </Modal>

            {editVehicleShow && <Modal show={editVehicleShow} className="PanelModal PanelGallery">
                <div className="modal-body">
                    <div className="Category">
                        <a href="javascript:void(0);" className="Close" onClick={handleEditVehicleClose}>×</a>
                        <h3>Edit Vehicle</h3>   
                        <FORM onSubmit={handleVehicleEditSubmitV2}>

                            <div className="form-group">  
                                <label> { t('sales.vehicle.Make') } </label>
                                <select className="form-control" name="make" autoComplete="off" defaultValue={vehicleEditData.make} onChange={handleVehicleEditChange}>
                                    <option value=""> Select {t('sales.vehicle.Make')}</option>
                                    {
                                        manufacturerSalesData && manufacturerSalesData.length>0? manufacturerSalesData.map((item, i)=>
                                            <option value={item._id}> {item._id} </option>
                                        )
                                        :''
                                    }
                                </select>
                                <span style={{ color: "red" }}>{makeEditEmpty}</span>
                            </div> 
                            <div className="form-group">  
                                <label> { t('sales.vehicle.Model') } </label>
                                <select className="form-control" name="model" defaultValue={vehicleEditData.model} onChange={handleVehicleEditChange}>
                                    <option value=""> Select Model </option>
                                    {
                                        modelSalesData && modelSalesData.length>0?
                                            modelSalesData.map((item, i) =>
                                                <option value={ item.model }> { item.model } </option>
                                            )
                                        :
                                        <option value={modelEdit}> { modelEdit } </option>
                                    }
                                </select>
                                <span style={{ color: "red" }}>{modelEditEmpty}</span>
                            </div> 

                            <div className="form-group">  
                                <label>Year</label>
                                    <input type="text" className="form-control" placeholder="Enter Year" name="year" value={vehicleEditData.year} onChange={handleVehicleEditChange} />
                                {/* <span style={{ color: "red" }}>{mileageEmpty}</span> */}
                            </div>
                            <div className="form-group">  
                                <label>Color</label>
                                    <input type="text" className="form-control" placeholder="Enter color" name="color" value={vehicleEditData.color} onChange={handleVehicleEditChange} />
                                {/* <span style={{ color: "red" }}>{mileageEmpty}</span> */}
                            </div>
                            <div className="form-group">  
                                <label>Condition</label>
                                    {/* <input type="text" className="form-control" placeholder="Condition" name="condition" value={condition} onChange={handleInputChange} /> */}
                                    <select className="form-control" name="condition" value={vehicleEditData.condition} onChange={handleVehicleEditChange}>
                                        <option value="" disabled> Select Vehicle condtion </option>
                                        <option value="New"> New </option>
                                        <option value="Used"> Used </option>
                                    </select>
                                {/* <span style={{ color: "red" }}>{mileageEmpty}</span> */}
                            </div>
                            <div className="form-group">  
                                <label>Description</label>
                                    <input type="text" className="form-control" placeholder="Enter description" name="description" value={vehicleEditData.description} onChange={handleVehicleEditChange} />
                                {/* <span style={{ color: "red" }}>{mileageEmpty}</span> */}
                            </div> 

                            
                            <div className="form-group">  
                                <label> { t('sales.vehicle.Mileage') } </label>
                                <input type="text" className="form-control" placeholder="Enter mileage" name="mileage" value={vehicleEditData.mileage} onChange={handleVehicleEditChange} />
                                <span style={{ color: "red" }}>{mileageEditEmpty}</span>
                            </div>

                            <div className="form-group">  
                                <label> { t('sales.vehicle.Type') } </label>
                                <input className="form-control" placeholder="Sports, etc" name="type" value={vehicleEditData.type} onChange={handleVehicleEditChange} />
                                <span style={{ color: "red" }}>{typeEmpty}</span>
                            </div> 

                            <div className="form-group">
                                <ul className="ImaesGroup">
                                    <li>
                                        <div className="AddImages">
                                            <span><i className="fa fa-plus" aria-hidden="true"></i></span>
                                            <p>Edit Images</p> 
                                            {/* Edit images */}
                                            <input type="file" accept="image/*" name="imagesEdit" onChange={uploadMultipleFiles}/>
                                        </div>
                                    </li>
                                    {
                                        vehicleEditData.imageGallery && vehicleEditData.imageGallery.length>0 && vehicleEditData.imageGallery.map((img, i) =>
                                            <li key={i}>
                                                <button type="button" className="Close" onClick={()=>removeSelectedEditImage(i)}>×</button>
                                                <img src={img} />
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>

                            <button type="submit" className="Accept"> Edit Vehicle</button>
                        </FORM>
                    </div>
                </div>
            </Modal>}

            <Modal show={deleteShow} className="PanelModal">
                <div className="modal-body"> 
                    <div className="DeclineText">
                        <a href="javascript:void(0);" className="Close" onClick={handleDeleteClose}>×</a>
                        <h3>Delete</h3>
                        <p>Are you sure you want to delete this Vehicle ?</p>
                        <h4> 
                            <a href="javascript:void(0);" onClick={handleDeleteClose}>no</a>
                            <a href="javascript:void(0);" onClick={handleDeleteVehicle}>Yes</a>
                        </h4>
                    </div>
                </div> 
            </Modal> 

            <Footer />
        </>
    );
}

export default SalesMyVehicle